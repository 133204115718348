
import styled from 'styled-components'

const Copa = () => {
  return (
    <CopaConEstilo width={55} height={55} viewBox="0 0 480.1 480.1" fill="#C0C0C0" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M204.013,358.2c-8.7,0-15.8,7.101-15.8,15.8c0,1.801,0.3,3.4,0.8,5h102c0.5-1.6,0.8-3.3,0.8-5c0-8.699-7.1-15.8-15.8-15.8
          h-5.8V267.1h5.8c8.7,0,15.8-7.1,15.8-15.799c0-4.5-1.899-8.5-4.899-11.401c-5.5,1.5-11.101,2.7-16.7,3.6c-9.9,1.7-20,2.5-30.2,2.5
          c-10.2,0-20.3-0.8-30.2-2.5c-5.6-0.9-11.2-2.199-16.7-3.6c-3,2.901-4.9,6.901-4.9,11.401c0,8.699,7.1,15.799,15.8,15.799h5.8V358.2
          H204.013z"/>
        <path d="M350.213,474.6V416.4c0-3-2.601-5.5-5.8-5.5h-15.7c-3.2,0-5.8-2.5-5.8-5.5v-10.1c0-3-2.601-5.5-5.801-5.5h-41.1h-72h-41.5
          c-3.2,0-5.8,2.5-5.8,5.5v10.1c0,3-2.6,5.5-5.8,5.5h-15.7c-3.2,0-5.8,2.5-5.8,5.5V474.6c0,3,2.6,5.5,5.8,5.5h209.2
          C347.513,480.1,350.213,477.6,350.213,474.6z"/>
        <path d="M476.213,11.9c-0.601-6.5-6.7-11.9-13.2-11.9H17.113c-6.8,0-12.6,5.2-13.2,11.9c-4.6,50.8,15.2,102.1,50.6,138.4
          c8.9,9.1,19,17.1,29.7,23.9c11,7.1,24.2,12.4,31,24.2c4.8,8.4,0.5,18.3-8.6,21.1c-1.1,0.3-2.1,0.5-3.1,0.5
          c-7.3,0-13.4-7.6-13.4-14.6s-5.4-10.4-10.8-10.4c-5.4,0-10.8,3.5-10.8,10.4c0,15.1,9.6,28.4,23.5,33.9c3.7,1.5,7.6,2.1,11.5,2.1
          c10.2,0,20.3-4.9,27-12.9c6.6-8,9.1-17.5,7.9-26.9c20.7,15.4,45,26.2,71.4,30.9c9.8,1.8,19.9,2.7,30.2,2.7
          c10.299,0,20.4-0.9,30.2-2.7c26.4-4.7,50.7-15.5,71.399-30.9c-1.199,9.4,1.2,18.9,7.9,26.9c6.7,8.1,16.8,12.9,27,12.9
          c3.8,0,7.7-0.7,11.5-2.1c13.9-5.4,23.5-18.8,23.5-33.9c0-7-5.4-10.4-10.8-10.4c-5.4,0-10.8,3.5-10.8,10.4c0,7-6.101,14.6-13.4,14.6
          c-1,0-2.1-0.2-3.1-0.5c-9.101-2.8-13.5-12.7-8.601-21.1c6.8-11.8,19.9-17.2,31-24.2c10.7-6.9,20.8-14.8,29.7-23.9
          C460.913,114,480.713,62.7,476.213,11.9z M24.813,24.8c0-1,0-2.1,0.1-3.1h50c-3.6,13.7-5.5,28.1-5.5,42.9c0,33.7,9.8,65,26.6,91.5
          C51.613,128.6,24.613,76.6,24.813,24.8z M384.013,156.1c16.8-26.4,26.6-57.8,26.6-91.5c0-14.8-1.899-29.2-5.5-42.9h50
          c0,1,0.101,2.1,0.101,3.1C455.413,76.6,428.413,128.6,384.013,156.1z"/>
      </g>
    </CopaConEstilo>
  )
}

const CopaConEstilo = styled.svg`
  width: 55px;
  height: 55px;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`

export default Copa
