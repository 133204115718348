
import styled from 'styled-components'

const Pelota = () => {
  return (
    <PelotaConEstilo width={45} height={45} viewBox="0 0 1000 1000" fill="#FFBD00" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M4487.2,4989.7c-296.6-32.7-728.5-126.1-1018.1-219.5c-474-156.5-1067.1-469.3-1471.1-779.9c-207.8-158.8-649.1-593.1-828.9-819.6c-161.1-203.2-373.6-518.4-373.6-553.4c0-11.7,91.1-53.7,203.2-95.7c987.7-364.3,1942.8-305.9,2914.1,172.8c681.8,336.2,1202.5,828.9,1571.5,1482.8c74.7,130.8,266.2,588.4,291.9,702.9c21,77.1,14,79.4-308.2,112.1C5187.7,5020,4769.7,5017.7,4487.2,4989.7z"/>
          <path d="M6434.6,4660.4c-378.3-1165.2-1151.2-2059.5-2239.3-2587.2c-681.8-331.6-1249.3-467-1949.8-467c-576.8,0-1099.8,102.7-1620.5,319.9c-74.7,30.4-144.8,49-156.5,42c-37.4-23.3-228.8-646.8-277.9-908.3c-242.8-1253.9,7-2556.9,691.2-3610c198.5-303.6,319.9-455.3,602.4-744.9c418-429.6,789.2-705.2,1296-959.7c249.9-126.1,719.2-315.2,735.5-298.9c4.7,4.7,49,123.8,100.4,263.8c247.5,684.2,555.8,1169.8,1046.1,1648.5C5267.1-2052.9,6096-1623.2,6960-1448.1c235.8,46.7,333.9,53.7,805.6,53.7c467-2.3,567.4-9.4,779.9-53.7c266.2-58.4,618.8-165.8,826.6-252.2c74.7-32.7,144.8-51.4,154.1-44.4c11.7,4.7,63,142.4,116.8,305.9c413.3,1239.9,326.9,2535.9-249.9,3710.4c-273.2,553.4-539.4,917.7-987.7,1354.3c-291.9,282.5-441.3,404-749.6,604.8c-233.5,151.8-756.5,411-976.1,483.4c-84,28-163.5,56-177.5,60.7C6486,4781.8,6457.9,4732.8,6434.6,4660.4z"/>
          <path d="M7230.8-2097.2C6182.4-2260.7,5222.7-2858.5,4667-3694.4c-219.5-331.6-502-987.7-441.3-1027.4c44.4-25.7,523-70,777.6-70c1053.1,0,2176.3,385.3,3007.5,1034.4c205.5,161.1,604.8,551.1,768.2,751.9c165.8,200.8,422.6,569.7,422.6,607.1c0,32.7-345.6,161.1-630.4,231.2C8136.9-2057.5,7644.1-2031.8,7230.8-2097.2z"/>
        </g>
      </g>
    </PelotaConEstilo>
  )
}

const PelotaConEstilo = styled.svg`
  width: 45px;
  height: 45px;

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }

  @media (max-width: 600px) {
    width: 15px;
    height: 15px;
  }
`

export default Pelota
  