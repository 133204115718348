/* eslint-disable react/style-prop-object */

import styled from 'styled-components'

const Logo = ({ ancho, alto, color }: { ancho?: number, alto?: number, color?: string }) => {
  return (
    <LogoonEstilo ancho={ancho} alto={alto} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(0.1, 0, 0, -0.1, 0, 512)" fill={color ?? '#2E86C1'} stroke="none">
        <path d="M415 4146 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -694 -3 -1504 3 -1468 3 -1472 24 -1499 11 -15 33 -37 48 -48 l27 -21 2139 0 2139 0 27 21 c15 11 37 33 48 48 21 27 21 28 21 1526 0 1498 0 1499 -21 1526 -11 15 -33 37 -48 48 l-27 21 -2127 2 c-1753 2 -2132 0 -2157 -11z m1485 -466 l-80 -160 -590 0 -590 0 0 160 0 160 670 0 670 0 -80 -160z m970 -20 c49 -99 90 -183 90 -188 0 -4 -90 -30 -200 -57 l-200 -49 -200 49 c-110 27 -200 53 -200 57 0 5 41 89 90 188 l90 180 220 0 220 0 90 -180z m1610 20 l0 -160 -590 0 -590 0 -80 160 -80 160 670 0 670 0 0 -160z m-2252 -562 l332 -83 333 83 332 83 628 -1 627 0 0 -960 0 -960 -1920 0 -1920 0 0 960 0 960 628 0 627 1 333 -83z"/>
        <path d="M2488 2620 c-112 -59 -109 -224 6 -279 53 -26 89 -26 140 -1 62 31 81 63 81 140 0 77 -19 109 -81 140 -50 25 -98 25 -146 0z"/>
        <path d="M2488 2140 c-112 -59 -109 -224 6 -279 53 -26 89 -26 140 -1 62 31 81 63 81 140 0 77 -19 109 -81 140 -50 25 -98 25 -146 0z"/>
        <path d="M 3511.22 -2746.93 L 3511.22 -2994.09 L 3758.38 -2994.09 L 3758.38 -2746.93 Z M 3511.22 -3116.93 L 3511.22 -3239.77 Q 3511.22 -3393.69 3671.06 -3489.89 L 3691.78 -3501.73 Q 3758.38 -3541.69 3758.38 -3609.77 Q 3758.38 -3660.09 3722.12 -3697.09 Q 3685.86 -3734.09 3634.06 -3734.09 L 3511.22 -3734.09 Q 3397.26 -3734.09 3388.38 -3609.77 L 3264.06 -3609.77 Q 3293.66 -3856.93 3511.22 -3856.93 L 3758.38 -3856.93 Q 3859.02 -3856.93 3931.54 -3784.41 Q 4004.06 -3711.89 4004.06 -3609.77 Q 4004.06 -3449.93 3863.46 -3387.77 L 3844.22 -3378.89 Q 3777.62 -3349.29 3768 -3321.17 Q 3758.38 -3293.05 3758.38 -3239.77 L 3758.38 -3116.93 Z" transform="matrix(1.241848, 0, 0, -1.082323, -885.51123, -1356.133179)"/>
        <path d="M 4443.381 -2905.215 L 4443.381 -2658.055 L 4196.221 -2658.055 L 4196.221 -2905.215 Z M 4443.381 -2535.215 L 4443.381 -2412.375 Q 4443.381 -2258.455 4282.061 -2162.255 L 4262.821 -2150.415 Q 4196.221 -2110.455 4196.221 -2042.375 Q 4196.221 -1990.575 4232.481 -1954.315 Q 4268.741 -1918.055 4319.061 -1918.055 L 4443.381 -1918.055 Q 4557.341 -1918.055 4566.221 -2042.375 L 4689.061 -2042.375 Q 4660.941 -1795.215 4443.381 -1795.215 L 4196.221 -1795.215 Q 4095.581 -1795.215 4022.321 -1867.735 Q 3949.061 -1940.255 3949.061 -2042.375 Q 3949.061 -2202.215 4089.661 -2264.375 L 4110.381 -2273.255 Q 4176.981 -2302.855 4186.601 -2330.975 Q 4196.221 -2359.095 4196.221 -2412.375 L 4196.221 -2535.215 Z" transform="matrix(1.226243, 0, 0, -1.084375, -3791.661621, -338.527344)"/>
      </g>
    </LogoonEstilo>
  )
}

const LogoonEstilo = styled.svg<{ ancho?: number, alto?: number, }>`
  width: ${props => props.ancho || '55'}px;
  height: ${props => props.alto || '55'}px;

  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
  }
`

export default Logo
